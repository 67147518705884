import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

function AfasConnector({}) {
  const data = useStaticQuery(graphql`
    query {
      marktplaats: file(relativePath: { eq: "marktplaats-connector.png" }) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO
        description={`Marktplaats Admarkt add-on for Google Sheets`}
        pathname={`/add-ons/marktplaats-admarkt-for-google-sheets`}
        title={`Marktplaats Admarkt add-on`}
      />
      <Layout
        subtitle={`for Google Sheets`}
        title={`Marktplaats Admarkt add-on`}
      >
        <main className="bg-gladior-dark ">
          <section className="container mx-auto px-6 py-10  max-w-7xl prose  ">
            <h3>Overzicht</h3>

            <p className="text-base leading-6 text-gray-300 max-w-3xl">
              <a
                className="text-gladior-pink"
                href="https://github.com/Gladior-B-V/marktplaats-admarkt-for-google-sheets/issues"
              >
                Report an issue
              </a>
              {` `}
              <br />
              <a className="text-gladior-pink" href="/contact">
                Support
              </a>
              {` `}
              <br />
              <a className="text-gladior-pink" href="/voorwaarden/">
                Terms and conditions
              </a>
            </p>

            <p>
              Werk jij voor een organisatie die adverteert op Marktplaats
              Admarkt? Gebruik dan Marktplaats Admarkt voor Google Sheets, de
              online tool voor het opvragen van data uit Marktplaats Admarkt.
            </p>

            <h3>Marktplaats Admarkt add-on installeren</h3>

            <ol>
              <li>
                Ga naar{" "}
                <a href="https://gsuite.google.com/marketplace/app/marktplaats_admarkt/449949851384">
                  G Suite Marketplace
                </a>
              </li>
              <li>
                Log in het Google account waar je de connector mee wil
                gebruiken.
              </li>
              <li>
                Autoriseer de connector toegang tot je Google Account zodat de
                connector gebruikt kan worden!
              </li>
            </ol>

            {/* <p>
              Tip! Je kan de Marktplaats Admarkt add-on ook direct installeren
              vanuit je Google Sheet
            </p> */}

            <h3>Hoe werkt de Marktplaats Admarkt add-on? </h3>

            <ol>
              <li>Voeg een Marktplaats Admarkt account toe.</li>

              <li>
                Via <code>add-ons</code> →{" "}
                <code>Marktplaats Admarkt for Google Sheets</code> →{" "}
                <code>Create new Report</code> → Kies eventuele filters en ga
                door naar <code>Run Report</code>.
              </li>
              <Img fixed={data.marktplaats.childImageSharp.fixed} />
              <li>
                Nu heb je toegang tot de data van Marktplaats Admarkt. De data
                kan je met een zelf gekozen interval opnieuw ophalen zodat de
                data altijd up-to-date is. Interval selecteren doe je via{" "}
                <code>add-ons</code> →{" "}
                <code>Marktplaats Admarkt for Google Sheets</code> →{" "}
                <code>Schedule Reports</code>.
              </li>
            </ol>
          </section>
        </main>
      </Layout>
    </>
  );
}
export default AfasConnector;
