import PropTypes from "prop-types";
import React from "react";
import Footer from "../components/Footer";
import HeroBackground from "../components/HeroBackground";
import Header from "./header";
function Layout({ children, title, subtitle, heroBackgroundImage }) {
  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Header />
      <HeroBackground heroBackgroundImage={heroBackgroundImage}>
        <section className="w-full  mt-32  flex flex-col flex-1 md:justify-center">
          <section className="text-white flex flex-col justify-center content-center items-center max-w-5xl  container mx-auto  text-center my-8 h-full md:h-40">
            {subtitle ? (
              <>
                <h1 className=" mt-2  text-4xl leading-tight xl:text-4xl font-semibold font-display text-white">
                  {title}
                </h1>
                <p className="mt-3 text-lg max-w-xl lg:max-w-3xl text-gray-400 xl:text-2xl font-display ">
                  {subtitle}
                </p>
              </>
            ) : (
              <h1 className=" mt-2  text-4xl leading-tight xl:text-4xl font-semibold font-display text-white">
                {title}
              </h1>
            )}
          </section>
        </section>
      </HeroBackground>

      {children}

      <Footer isDark={true} />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
